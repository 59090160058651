
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import { dateToDateString, dateToTime } from "@/app/infrastructures/misc/Utils";
import { RefundConfigurationController } from "@/app/ui/controllers/RefundConfigurationController";
import { ConfigurableRefund } from "@/domain/entities/RefundConfiguration";

@Options({
  components: {
    DetailLayout
  }
})
export default class Detail extends Vue {
  mounted() {
    this.fetchDetailData();
  }

  get id(): any {
    return this.$route.params.id;
  }

  fetchDetailData() {
    RefundConfigurationController.getDetailData(this.id);
  }

  get detailData() {
    return RefundConfigurationController.refundConfigurationDetail;
  }

  get isLoading() {
    return RefundConfigurationController.isLoading;
  }

  get isError() {
    return RefundConfigurationController.isError;
  }

  get errorCause() {
    return RefundConfigurationController.errorCause;
  }

  // table
  columns = [
    {
      name: "Status Terakhir",
      styleHead: "w-44 text-left whitespace-no-wrap",
      render: (item: ConfigurableRefund) =>
        `<span class="font-normal text-black-lp-300 whitespace-no-wrap">${item.refundSttLastStatus}</span>`
    },
    {
      name: "Jumlah<br>Refund (%)",
      styleHead: "w-28 text-left whitespace-no-wrap",
      render: (item: ConfigurableRefund) =>
        `<span class="font-normal text-black-lp-300">${item.refundAmount}%</span>`
    },
    {
      name: "Refund<br>Penalty (%)",
      styleHead: "w-32 text-left whitespace-no-wrap",
      render: (item: ConfigurableRefund) =>
        `<span class="font-normal text-black-lp-300">${item.refundPenalty}%</span>`
    },
    {
      name: "Parameter Perhitungan",
      styleHead: "w-44 text-left whitespace-no-wrap",
      render: (item: ConfigurableRefund) =>
        `<span class="font-normal text-black-lp-300 whitespace-no-wrap">${item.refundQuantifier
          .split(",")
          .map(quantifier => {
            if (quantifier === "base_rate") return "Base Tariff";
            if (quantifier === "shipping_surcharge") return "Surcharge";
            return quantifier;
          })
          .join(" + ")}</span>`
    },
    {
      name: "Refund<br>Asuransi (%)",
      styleHead: "w-32 text-left whitespace-no-wrap",
      render: (item: ConfigurableRefund) =>
        `<span class="font-normal text-black-lp-300">${item.refundAmountInsurance}%</span>`
    },
    {
      name: "Pengecualian Kota Asal",
      styleHead: "w-52 text-left whitespace-no-wrap",
      render: (item: ConfigurableRefund) =>
        `<span class="font-normal text-black-lp-300">${item.refundOriginCityExcludeDetail
          .map(city => city.name)
          .join(", ")}</span>`
    }
  ];

  pagination = {
    page: 1,
    limit: 100
  };

  // route navigatiion
  goBack() {
    this.$router.push("/tarif/configurable-price");
  }
  goToEdit() {
    this.$router.push(
      `/tarif/configurable-price/refund-config/${this.$route.params.id}/edit`
    );
  }

  // Format Date
  formatDate(date: string) {
    return `${dateToDateString(date)}, ${dateToTime(date)} WIB`;
  }
}
