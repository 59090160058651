
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import CityExclude from "@/app/ui/components/city-exclude/index.vue";
import { RefundConfigurationController } from "@/app/ui/controllers/RefundConfigurationController";

@Options({
  components: {
    DetailLayout,
    CityExclude
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.handleOpenSuccess(false);
      next();
    }
  }
})
export default class Edit extends Vue {
  mounted() {
    this.fetchDetail();
  }

  get dataDetail() {
    return RefundConfigurationController.refundConfigurationDetail;
  }
  get isStatusActive() {
    return this.dataDetail.configurablePriceStatus.toLowerCase() === "active";
  }
  refundQuantifierParser(val: string) {
    return val
      .split(",")
      .map(item => {
        if (item === "base_rate") return "Base Tariff";
        if (item === "shipping_surcharge") return "Surcharge";
        return item;
      })
      .join(" + ");
  }
  calculateRefundPenalty(refundAmount: any) {
    return (
      100 - Number(refundAmount.toString().replace(",", "."))
    ).toLocaleString("id");
  }

  async onEdit() {
    // to do logic on edit
    this.onCloseModalConfirmation();
    const resp = await RefundConfigurationController.onEditRefundConfiguration({
      configPriceId: this.dataDetail.configurablePriceId,
      configPriceStatus: this.dataDetail.configurablePriceStatus,
      configPriceDescription: this.dataDetail.configurablePriceDescription,
      configurableRefund: this.dataDetail.configurableRefund
    });
    this.handleOpenSuccess(resp.success);
  }
  get isValidated() {
    for (const configRefund of this.dataDetail.configurableRefund) {
      if (configRefund.refundOriginCityExcludeDetail.length === 0) {
        return false;
      }
      if (
        configRefund.refundOriginCityExcludeDetail.find(
          (item: any) => item.code === "-" || item.code === ""
        )
      ) {
        return configRefund.refundOriginCityExcludeDetail.length > 1;
      }
    }
    return !this.errorRefundAmount.includes(true);
  }

  openSuccess = false;
  handleOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }
  onCloseSuccess() {
    this.goBack();
  }
  goBack() {
    this.$router.push(
      `/tarif/configurable-price/refund-config/${this.$route.params.id}`
    );
  }

  // Save data
  openModalConfirmation = false;
  onOpenModalConfirmation() {
    this.openModalConfirmation = true;
  }
  onCloseModalConfirmation() {
    this.openModalConfirmation = false;
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // Toggle Status
  openStatus = false;
  onOpenStatus() {
    this.openStatus = true;
  }
  onCloseStatus() {
    this.openStatus = false;
  }
  changeStatus() {
    this.onCloseStatus();
    if (this.isStatusActive) {
      this.dataDetail.configurablePriceStatus = "inactive";
    } else {
      this.dataDetail.configurablePriceStatus = "active";
    }
  }
  get contentOpenStatus() {
    if (this.isStatusActive) {
      return {
        title: "Konfirmasi Penonaktifan",
        message: `Anda yakin ingin menonaktifkan Harga “${this.dataDetail.configurablePriceName}” ?`,
        button: "Non-aktifkan",
        image: "warning"
      };
    }
    return {
      title: "Konfirmasi Pengaktifan",
      message: `Anda yakin ingin mengaktifkan Harga “${this.dataDetail.configurablePriceName}” ?`,
      button: "Aktif",
      image: "are-you-sure"
    };
  }

  get id(): any {
    return this.$route.params.id;
  }

  fetchDetail() {
    RefundConfigurationController.getDetailData(this.id);
  }
  get isLoading() {
    return RefundConfigurationController.isLoading;
  }

  formatFloat(value: string) {
    return value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
  }
  errorRefundAmount: Array<boolean> = [];
  validateRefundAmount(value: string, index: number) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0 || value === "") {
      this.errorRefundAmount[index] = true;
      return;
    }
    this.errorRefundAmount[index] = Number(value) > 100;
  }
}
